import LandingPage from "./LandingPage";
import React, { useEffect, useState } from "react";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";

export interface ArtistImageProps {
  wide: string;
  square: string;
}

export interface ArtistProps {
  artist: string;
  description?: string;
  images: ArtistImageProps;
  path: string;
  subtitle: string;
  featured?: boolean;
}

export interface RegionConfigProps {
  logoUrl?: string;
  name?: string;
  states?: string[];
  artists: ArtistProps[];
  regionId?: string;
  order: number;
}

export default function RegionPage() {
  const [loading, setLoading] = useState(true);
  const [regionConfig, setRegionConfig] = useState<RegionConfigProps>();
  // const [regionArtists, setRegionArtists] = useState<ArtistProps[]>([]);
  const [featuredArtist, setFeaturedArtist] = useState<ArtistProps>();
  const { regionId } = useParams();
  const db = getFirestore();
  const navigate = useNavigate();

  const getRegionDoc = async (docId: string) => {
    const snap = await getDoc(
      doc(db, `hub_pages/${process.env.REACT_APP_DOMAIN}/regions`, docId)
    );
    if (snap.exists()) {
      const snapData = snap.data() as RegionConfigProps;
      snapData.artists.reverse();
      const lastArtist = snapData.artists[0];

      setRegionConfig(snapData);
      
      setFeaturedArtist(lastArtist)
      setLoading(false);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (regionId) {
      setLoading(true);
      getRegionDoc(regionId);
    }
  }, [regionId]);


  if (loading || !regionConfig ) return null;

  return <LandingPage isRegion={true} regionName={regionConfig.name} regionLogo={regionConfig.logoUrl} artists={regionConfig.artists} latestArtist={featuredArtist} />;
}
