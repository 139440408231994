import { useEffect, useState } from "react";
import styled from "styled-components";
import { query, collection, getDocs, getFirestore, orderBy } from "firebase/firestore";
import { ReactComponent as ChevronIcon } from "../../svg/chevron-circle-down.svg";
import { RegionConfigProps } from "../../LandingPage/RegionPage";

export const DropdownMenu = () => {
  const [isActive, setIsActive] = useState(false);
  const [regions, setRegions] = useState<RegionConfigProps[]>([]);
  const onClick = () => setIsActive(!isActive);

  useEffect(() => {
    const getRegions = async () => {
      const db = getFirestore();
      const snap = await getDocs(query(collection(db, `hub_pages/${process.env.REACT_APP_DOMAIN}/regions`), orderBy("order")));
      const regions = snap.docs.map(doc => {
        return {
          ...doc.data(),
          regionId: doc.id,
        } as RegionConfigProps;
      });
      setRegions(regions);
    };
    getRegions();
  }, []);

  return (
    <MenuContainer>
      <MenuText onClick={onClick}>
        Select your area
        <Svg active={isActive ? true : false} />
      </MenuText>
      <Ul active={isActive ? true : false}>
        {regions.map((region, i) => (
          <li key={i}>
            <a href={`/${region.regionId}`}>{region.name}</a>
          </li>
        ))}
      </Ul>
    </MenuContainer>
  );
};

const MenuText = styled.div`
  color: white;
  border: 2px solid white;
  text-transform: uppercase;
  font-size: min(1.6vw, 1.4rem);
  font-weight: 600;
  padding: 20px 10px 20px 30px;
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  cursor: pointer;

  @media (max-width: 1220px) {
    padding: 10px 10px 10px 20px;
  }
  @media (max-width: 1020px) {
    padding: 10px 0px 8px 20px;
    font-size: min(2vw, 1.1rem);
    width: 100%;
    align-items: center;
  }

  @media (max-width: 520px) {
    font-size: 0.8rem;
  }
`;
const Svg = styled(ChevronIcon)<{ active: boolean }>`
  width: 30px;
  height: 30px;
  display: inline-block;
  /* margin-bottom: -5px; */
  margin-right: 10px;
  padding: 0;
  transition: transform 0.4s ease;

  @media (max-width: 1020px) {
    width: 20px;
    height: 20px;
    margin-bottom: 2px;
    margin-right: 20px;
  }

  ${(props) => props.active && "transform: rotate(180deg);"}
`;

const MenuContainer = styled.div`
  position: relative;

  @media (max-width: 1020px) {
    display: flex;
    width: 100%;
    align-items: center;
  }
`;

const Ul = styled.ul<{ active: boolean }>`
  background: rgba(255, 255, 255, 1);
  position: absolute;
  top: 100px;
  left: 0px;
  width: 100%;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  list-style: none;
  padding: 0;
  margin: 0;
  columns: 2;
  z-index: 1;

  @media (max-width: 1220px) {
    top: 80px;
  }
  @media (max-width: 1020px) {
    top: 70px;
  }

  & > li > a {
    text-decoration: none;
    border-bottom: 1px solid #dddddd;
    color: #00095b;
    padding: 15px 1px 15px 10px;
    display: block;
    font-size: min(1vw, 0.85rem);
    text-transform: uppercase;

    @media (max-width: 1020px) {
      font-size: max(1.3vw, 0.7rem);
    }
  }

  ${(props) =>
    props.active &&
    "opacity: 1; visibility: visible ; transform: translateY(0)"}
`;
